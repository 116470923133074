<template>
   <div class="container-fluid">
  <div class="row">
     <div class="col-sm-12">
        <div class="card">
           <div class="card-header d-flex justify-content-between">
              <div class="header-title">
                 <h4 class="card-title">Lista de documentos</h4>
              </div>
           </div>
           <div class="card-body">
              <div class="table-responsive">
                  <div class="row justify-content-between">
                     <div class="col-sm-6 col-md-6">
                        <b-input v-model="filter" placeholder="Pesquisar"></b-input>
                        <br>
                     </div>
                     <div class="col-12 col-sm-6">
                       <select class="form-control form-control" v-model="currentType">
                          <option selected disabled>Type</option>
                          <option value="INVOICE">Fatura</option>
                          <option value="CREDIT">Nota de crédito</option>
                       </select>
                     </div>
                     <div class="col-12 py-2">
                        <div class="d-flex justify-content-end">
                           <a href="#" class="btn bg-primary" @click="syncDocs">
                              Sincronizar
                           </a>
                        </div>
                     </div>
                  </div>
               <b-table 
                  :items="docs" 
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  @filtered="onFiltered"
                 >
                  <template #cell(id) = "{ item }">
                     {{ item.id ? item.id : item }}
                  </template>
                  <template #cell(descrição) = "{ item }">
                     {{ item.Descrição ? item.Descrição : '-' }}
                  </template>
                  <template #cell(PrecoMedio) = "{ item }">
                     {{ item['Preço médio'] ? item['Preço médio'] : '-' }}
                  </template>
                  <template #cell(facturado) = "{ item }">
                     {{ item.Facturado ? item.Facturado : '-' }}
                  </template>
                  <template #cell(valor) = "{ item }">
                     {{ item.Valor ? item.Valor : '-' }}
                  </template>
                  <template #cell(iva) = "{ item }">
                     {{ item.IVA ? item.IVA : 0 }}
                  </template>
                  <template #cell(actions) = "{ item }">
                     <div class="d-flex align-items-center list-user-action">
                        <a 
                           class="btn btn-sm bg-primary mr-1" 
                           target="_blank" 
                           :href="
                              currentType === 'INVOICE' 
                              ? 
                              item.id ? 
                              finalUrlInvoices + item.id + '.pdf' : 
                              finalUrlInvoices + item + '.pdf' 
                              : 
                              item.id ? 
                              finalUrlCredits + item.id + '.pdf' :
                              finalUrlCredits + item + '.pdf'"
                           data-toggle="tooltip" 
                           data-placement="top" 
                           data-original-title="Download" 
                        >
                           <i class="ri-download-line mr-0"></i>
                        </a>
                     </div>                     
                  </template>
               </b-table>
              </div>
              <div class="row justify-content-between mt-3">
                 <div id="user-list-page-info" class="col-md-6">
                    <span>A apresentar o intervalo {{startIndex + 1}}-{{endIndex > totalRows ? totalRows : endIndex}} de {{totalRows}} registos</span>
                 </div>
                 <div class="col-md-6">
                    <nav aria-label="Page navigation example">
                       <ul class="pagination justify-content-end mb-0">
                          <bPagination 
                             :total-rows="totalRows" 
                             v-model="currentPage" 
                             :per-page="perPage" 
                             @input="updatePage(currentPage)"
                           >
                           </bPagination>
                       </ul>
                    </nav>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
</template>
<script>

const INVOICES_MIN = 82;
const INVOICES_MAX = 373;
const CREDITS_MIN = 10;
const CREDITS_MAX = 22;
const INVOICE = "INVOICE";
// const CREDIT = "CREDIT";

export default {
  name:'UserList',
  data(){
     return{
        docs: [],
        currentType: INVOICE,
        baseUrl: "https://wilddourocrm.painelgestao.pt/storage/",
        fields: ['Id', 'Descrição', {key: 'PrecoMedio', label: 'Preço médio'}, 'Facturado', 'Valor', 'IVA', {actions: { label: 'Ações'}} ],
        invoiceUrl: "Factura-a-",
        creditsUrl: "Nota_de_Credito",
        perPage: 5,
        currentPage : 1,
        startIndex : 0,
        endIndex : 5,
        filter: null,
        totalRows: 1,
        invoicing: []
     }
  },
  mounted () {
     this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/facturacao.csv');
     this.onContextChanged();
     this.totalRows = this.numberOfDocs;  
  },
  computed:{
     finalUrlInvoices(){
       return this.baseUrl + this.invoiceUrl
     },
     finalUrlCredits(){
       return this.baseUrl + this.creditsUrl
     },
     numberOfInvoices()
     {
        return INVOICES_MAX - INVOICES_MIN; //291
     },
     numberOfCredits()
     {
        return CREDITS_MAX - CREDITS_MIN; // 12
     },
     numberOfDocs()
     {
       return this.currentType == INVOICE ? this.numberOfInvoices + 1 : this.numberOfCredits + 1;
     }
  },
  methods:{
     onContextChanged(){
        let startNum = this.currentType == INVOICE ? INVOICES_MIN : CREDITS_MIN;
        let length = this.numberOfDocs;

        var result = Array.from({length: length}, (_, i) => i + startNum);
        for(let indexD in result) {
           for(let indexI in this.invoicing) {
              if(result[indexD] == this.invoicing[indexI].Nome)
              {
                 result[indexD] = {
                    'id': this.invoicing[indexI].Nome,
                    'Descrição': this.invoicing[indexI].Descrição,
                    'Quantidade': this.invoicing[indexI].Quantidade,
                    'Preço médio': this.invoicing[indexI]['Preço médio'],
                    'Facturado': this.invoicing[indexI].Facturado,
                    'Valor': this.invoicing[indexI].Valor,
                    'IVA': this.invoicing[indexI]['IVA']
                 }
                 break;
              }
           }
        }
        
        //

         
         let completeDocs = [];
         let incompleteDocs = [];

         for (const [key, value] of Object.entries(result)) {
            console.log(key);
            if(value["Descrição"])
            {
               completeDocs.push(value);
            } 
            else incompleteDocs.push(value);
         }

         this.docs = completeDocs.concat(incompleteDocs);
     },
     updatePage : function(activePage) {
        this.currentPage = activePage;
        this.startIndex = (this.currentPage * 5) - 5;
        this.endIndex = this.startIndex + 5;
     },
     onFiltered(filteredItems) {
       // Trigger pagination to update the number of pages due to filtering
       this.totalRows = filteredItems.length
       this.currentPage = 1
     },
     readTextFile(file) {
           this.invoicing = [];
           let self = this;

           let rawFile = new XMLHttpRequest();
           rawFile.open("GET", file, false);
           rawFile.onreadystatechange = function ()
           {
              if(rawFile.readyState === 4)
               {
                 if(rawFile.status === 200 || rawFile.status == 0)
                 {
                    let csv = rawFile.responseText;
                    let lines = csv.split('\n');

                    // Get structure of object
                    let headers = lines[0].split(";") 

                    for(let line = 1; line < lines.length; line++){
                       let values = lines[line].split(";");
                       let invoice = {};

                       for (let index = 0; index < values.length - 1; index++) {
                          let key = headers[index];
                          invoice[key] = values[index];
                       }
                       // invoice.id = uuidv4();
                       self.invoicing.push(invoice);
                    }        
                 }
              }
           }
           rawFile.send(null);
        },
        syncDocs()
         {
            setTimeout(function(){alert('Documentos sincronizados com sucesso.')}, 1000);
         }
  },
  watch:
  {
     currentPage: function(){
        this.onContextChanged()
     },
     currentType: function(val){
        if(val) {
           this.onContextChanged();
           this.currentPage = 1;
           this.totalRows = this.numberOfDocs;
        }
     }
  }
}
</script>